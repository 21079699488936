<template lang="pug">
  .login
    .login__container.container

      //- Заголовок страницы
      h1.login__title {{ title }}

      //- Форма авторизации
      form.login__form

        //- Логин
        .form-el
          .form-el__title Логин
          .form-el__body
            el-input(
              v-model="user"
              type="text"
              placeholder="Введите логин"
            )
            p(v-if="isError" style="color: red;") Неверный логин или пароль

        //- Пароль
        .form-el
          .form-el__title Пароль
          .form-el__body
            el-input(
              v-model="password"
              placeholder="Введите пароль"
              show-password
            )
            p(v-if="isError" style="color: red;") Неверный логин или пароль

        //- Кнопка входа
        button.login__btn.btn.btn--main(
          type="primary"
          @click.prevent="login()"
        ) Войти
</template>

<script>
export default {
  name: 'Login',
  
  data() {
    return {
      title: 'Авторизация',
      user: '',
      password: '',
      isLoginVisible: false,
      isError: false,
      errors: {}
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters['auth/isAuthenticated']
    },
    username() {
      return this.$store.getters['auth/username']
    }
  },
  methods: {
    login() {
      const user = this.user;
      const password = this.password;
      this.$store.dispatch("auth/login", {username: user, password: password}).then(() => {
        this.isLoginVisible = false;
        this.$router.push({ name: 'Main' });
      }).catch(error => {
        this.isError = true;
        console.log(error);
      })
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  }
}
</script>
